html,
body {
    padding: 0;
    margin: 0;
    background-color: var(--joy-palette-background-surface);
}


.verticalCenter {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
}

.flexBottom {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-end;
}
.custom-shape-divider-top-1705782886 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    .shape-fill{
        fill: var(--joy-palette-neutral-900, #0B0D0E);
    }
}

/***
* Waves
**/
.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}
.parallax--inverted>use {
    animation-direction: reverse;
}
.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }
    100% {
        transform: translate3d(85px, 0, 0);
    }
}
